require("core-js/modules/es6.regexp.replace");

var config = {
  siteTitle: "Gingerbox",
  // Site title.
  siteTitleShort: "Gingerbox",
  // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "Sweet Gingerbox",
  // Alternative site title for SEO.
  siteLogo: "/logos/Gingerbox_Logo_02.jpg",
  // Logo used for SEO and manifest.
  siteUrl: "https://www.gingerbox.net",
  // Domain of your website without pathPrefix.
  pathPrefix: "/gingerbox",
  // Prefixes all links. For cases when deployed to example.github.io/gatsby-material-starter/.
  fixedFooter: false,
  // Whether the footer component is fixed, i.e. always visible
  siteDescription: "Gingerbox: A Sweet Tradition.",
  // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml",
  // Path to the RSS file.
  siteFBAppID: "",
  // FB Application ID for using app insights
  siteGATrackingID: "UA-108431118-1",
  // Tracking code ID for google analytics.
  disqusShortname: "https-gingerbox",
  // Disqus shortname.
  postDefaultCategoryID: "gingerbread_house",
  // Default category for posts.
  dateFromFormat: "YYYY-MM-DD",
  // Date format used in the frontmatter.
  dateFormat: "MM/DD/YYYY",
  // Date format for display.
  userName: "Gingerbox",
  // Username to display in the author segment.
  userEmail: "gingerbox@gingerbox.net",
  // Email used for RSS feed's author segment
  userTwitter: "",
  // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "Greenville, South Carolina",
  // User location to display in the author segment.
  userAvatar: "/logos/sublogo_redcircle_02.jpg",
  // User avatar to display in the author segment.
  userDescription: "Gingerbox was born out of a family tradition of hosting a gingerbread house decorating party with friends and family every year.  We have learned a lot over the past several years, and we hope to share some of that information with you.  Comments on this website are currently unavailable.  If you have any questions or comments, please send them directly to us at gingerbox@gingerbox.net.  Thanks!",
  // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [{
    label: "Gingerbread Houses",
    url: "/categories/gingerbread-house",
    iconClassName: "fa fa-home"
  }, {
    label: "Gingerbread House Party",
    url: "/categories/gingerbread-party",
    iconClassName: "fa fa-users"
  }, {
    label: "Cookies",
    url: "/categories/cookies",
    iconClassName: "fa fa-circle"
  }, {
    label: "Recipes",
    url: "/categories/recipe",
    iconClassName: "fa fa-magic"
  }, {
    label: "Projects",
    url: "/categories/gingerbread-project",
    iconClassName: "fa fa-gift"
  }],
  copyright: "Copyright © 2019. Gingerbox",
  // Copyright string for the footer of the website and RSS feed.
  welcomeMessage: "Welcome to Gingerbox!  We are here to share our love for making gingerbread houses and gingerbread cookies with you, as well as providing you with a few tips and tricks we've picked up along the way.",
  welcomeLogo: "/logos/Gingerbox_Logo_02.jpg"
}; // Validate
// Make sure pathPrefix is empty if not needed

if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = "/" + config.pathPrefix.replace(/^\/|\/$/g, "");
} // Make sure siteUrl doesn't have an ending forward slash


if (config.siteUrl.substr(-1) === "/") config.siteUrl = config.siteUrl.slice(0, -1); // Make sure siteRss has a starting forward slash

if (config.siteRss && config.siteRss[0] !== "/") config.siteRss = "/" + config.siteRss;
module.exports = config;
import React, { Component } from "react";
import ToolbarActions from "../ToolbarActions";
import Footer from "../Footer";
import NavigationDrawer from "react-md/lib/NavigationDrawers";
import GetNavList from "./NavList";
import "./Navigation.scss";
import { Link } from "gatsby";
import Img from "gatsby-image";

//toolbarTitle={LocalTitle}
//toolbarTitle={config.siteTitle}

//<Img fluid={logo} />

class Navigation extends Component {
  render() {
    const { children, config, LocalTitle, logo } = this.props;
    const footerLinks = LocalTitle !== "About";

    return (
      <NavigationDrawer
        drawerTitle={config.siteTitle}
        toolbarTitleMenu={<Link to="/"><img src="/logos/sublogo_redcircle_truered_04.png" height="48"></img></Link>}
        contentClassName="main-content"
        navItems={GetNavList(config)}
        mobileDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY}
        tabletDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY}
        desktopDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY}
        toolbarActions={<ToolbarActions config={config} />}
        toolbarThemeType='colored'
      >
        <div className="main-container">{children}</div>
        <Footer userLinks={footerLinks} />
      </NavigationDrawer>
    );
  }
}




export default Navigation;

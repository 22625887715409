import React, { Component } from "react";
import {
  FacebookShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  RedditShareButton,
  FacebookShareCount,
  GooglePlusShareCount,
  LinkedinShareCount,
  RedditShareCount,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  GooglePlusIcon,
  LinkedinIcon,
  RedditIcon
} from "react-share";
import urljoin from "url-join";
import config from "../../../data/SiteConfig";
import "./SocialLinks.scss";

class SocialLinks extends Component {
  render() {
    const { postNode, postPath, mobile } = this.props;
    const post = postNode.frontmatter;
    const url = urljoin(config.siteUrl, config.pathPrefix, postPath);
    const iconSize = mobile ? 36 : 48;
    const filter = count => (count > 0 ? count : "");
    const renderShareCount = count => (
      <div className="share-count">{filter(count)}</div>
    );

    //<RedditShareButton url={url} title={post.title}>
    //<RedditIcon round size={iconSize} />
    //<RedditShareCount url={url}>
    //  {count => renderShareCount(count)}
    //</RedditShareCount>
    //</RedditShareButton>

    //<GooglePlusShareButton url={url}>
    //<GooglePlusIcon round size={iconSize} />
    //<GooglePlusShareCount url={url}>
    //  {count => renderShareCount(count)}
    //</GooglePlusShareCount>
    //</GooglePlusShareButton>

    //<LinkedinShareButton
    //url={url}
    //title={post.title}
    //description={postNode.excerpt}
    //>
    //<LinkedinIcon round size={iconSize} />
    //<LinkedinShareCount url={url}>
    //  {count => renderShareCount(count)}
    //</LinkedinShareCount>
    //</LinkedinShareButton>

    //<TelegramShareButton url={url}>
    //  <TelegramIcon round size={iconSize} />
    //</TelegramShareButton>

    //<InstagramShareButton url={url} quote={postNode.excerpt}>
    //<InstagramIcon round size={iconSize} />
    //<InstagramShareCount url={url}>
    //  {count => renderShareCount(count)}
    //</InstagramShareCount>
    //</InstagramShareButton>

    return (
      <div className="social-links">

        <TwitterShareButton url={url} title={post.title}>
          <TwitterIcon round size={iconSize} />
        </TwitterShareButton>

        <FacebookShareButton url={url} quote={postNode.excerpt}>
          <FacebookIcon round size={iconSize} />
          <FacebookShareCount url={url}>
            {count => renderShareCount(count)}
          </FacebookShareCount>
        </FacebookShareButton>

      </div>
    );
  }
}

export default SocialLinks;
